import React from 'react'
import Footersect from './inc/Footersect'
import Headersect from './inc/Headersect'

function Homepage(props) {
  return (
    <>
        <Headersect WeblogoImg={props.WeblogoImg} />

        <section className='about-part comm_padd'>
          <div className='container'>
            <div className='content-box'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='box img-box'>
                          <img src={props.AboutImg} alt="none" className="img-fluid img1" />
                          <img src={props.AboutshapeImg} alt="none" className="img-fluid img2" />
                          <img src={props.AboutbgshapeImg} alt="none" className="img-fluid img3" />

                          <a id="play-video" className="video-play-button" href="https://www.youtube.com/watch?v=vY_19T4jCSA"><span></span></a>
              
                          <div id="video-overlay" className="video-overlay">
                              <a className="video-overlay-close"></a>
                          </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='box'>
                
                  <div className='title-box'>

                    <h3>FAILURE IS THE CONDIMENT THAT GIVES <span>SUCCESS.</span></h3>
                    <p>Are you interested in being the best version of yourself? Increase your athletic performance? In need of life change? Though this journey may not be easy, it will be beyond rewarding. Stay committed to your goals and stay committed to yourself. You are not in this alone, "We are in this together"!

                    </p>

                    </div>
                    <a href="https://thenutritionmatrix.thinkific.com/" className="cbtn cbnt1 fadeInDown animated">PLEASE FREE MY MIND NUTRITION COURSE <i className="fas fa-download"></i></a>

                  </div>
                </div>
                

              </div>

            </div>

          </div>

        </section>
        <section className='experience_skill-part comm_padd'>
          <div className='container'>
          <div className="title-box">
            <div className="row">
                <div className="col-lg-6">
                    <div className="box">
                        <h5>MY EXPERIENCE</h5>
                        <h3>EXPERIENCE AND <span>SKILL</span>
                        </h3>
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="box box2">
                        <div className="row">
                            <div className="col-10">
                                <p>What defines me? Accepting a challenge and a commitment to excellence.

                                </p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
                    </div>

            <div className='content-box'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='box img-box'>
                          <img src={props.AboutImg} alt="none" className="img-fluid img1" />
                          <img src={props.AboutshapeImg} alt="none" className="img-fluid img2" />
                          <img src={props.AboutbgshapeImg} alt="none" className="img-fluid img3" />

                          <a id="play-video" className="video-play-button" href="https://www.youtube.com/watch?v=vY_19T4jCSA"><span></span></a>
              
                          <div id="video-overlay" className="video-overlay">
                              <a className="video-overlay-close"></a>
                          </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='box'>
                
                  <div className='title-box'>

                    <h3>FAILURE IS THE CONDIMENT THAT GIVES <span>SUCCESS.</span></h3>
                    <p>Are you interested in being the best version of yourself? Increase your athletic performance? In need of life change? Though this journey may not be easy, it will be beyond rewarding. Stay committed to your goals and stay committed to yourself. You are not in this alone, "We are in this together"!

                    </p>

                    </div>
                    <a href="https://thenutritionmatrix.thinkific.com/" className="cbtn cbnt1 fadeInDown animated">PLEASE FREE MY MIND NUTRITION COURSE <i className="fas fa-download"></i></a>

                  </div>
                </div>
                

              </div>

            </div>

          </div>

        </section>

        <Footersect WeblogoImg={props.WeblogoImg} />
    </>
  )
}

export default Homepage