import React from 'react'

function Footersect(props) {
  return (

        <>
        <footer className="footer-part">
            <div className="container">

                <div className="content-box">
                    <div className="box">
                        <ul>
                            <li> <a href="/">HOME</a></li>
                            <li> <a href="#ABOUT">ABOUT</a></li>
                            <li> <a href="#PRICING">PRICING</a></li>
                            <li> <a href="#CONTACT">CONTACT</a></li>
                            <li> <a href="#TESTIMONIAL">TESTIMONIALS</a></li>
                            <li> <a href="#BLOG">BLOG</a></li>
                        </ul>

                        <div className="social-icon-box">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                            <a href="#"><i className="fas fa-envelope"></i></a>
                        </div>

                        <a href="#" className="logo"><img src={props.WeblogoImg} alt="none" className="img-fluid" /></a>

                        <p>Medical Disclaimer: All information is for educational purposes only. Nothing herein should be construed as constituting individual medical advice. If you have a medical illness, consult your physician. Consult your physician prior to dietary changes. Consult your physician prior to starting an exercise program. The information offered here is given in good faith for free and is not intended to create a doctor, patient relationship, or as an alternative to seeking proper medical advice for any conditions you have, nor must you delay seeking professional medical advice if you believe you have a medical condition. We ask all to consult a medical professional before embarking on any major health, fitness, exercise or lifestyle change.</p>

                        <p>© COPYRIGHT 2022. ALL RIGHTS RESERVED.</p>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footersect