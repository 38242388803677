import React from 'react'

function Headersect(props) {
  return (
    <>
        
        

        <header className="header-part">
            <div className="container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"> <img src={props.WeblogoImg} alt="none" className="img-fluid" />
                            </a> 
                            
                    <button className="navbar-toggler" data-bs-toggle="offcanvas" href="#offcanvasleftsidebar" role="button" aria-controls="offcanvasExample">
                        <i className="fa-solid fa-bars"></i>                            
                    </button>
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav d-lg-flex d-none  ms-auto mb-2 mb-lg-0">
                            <li className="nav-item "><a className="nav-link" href="/">HOME<span className="sub"></span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#ABOUT">ABOUT<span className="sub"></span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#PRICING">PRICING<span className="sub"></span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#CONTACT">CONTACT<span className="sub"></span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#TESTIMONIAL">TESTIMONIALS<span className="sub"></span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#BLOG">BLOG<span className="sub"></span></a></li>

                        </ul>

                    </div>
                </div>
                </nav>

            </div>

        </header>
    </>
  )
}

export default Headersect