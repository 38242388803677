import React from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import WeblogoImg from './img/logo.png';
import videobg from './img/video_bg2.mkv';
import AboutshapeImg from './img/about-shape.svg';
import AboutbgshapeImg from './img/about-bg-shape.png';
import AboutImg from './img/about_img.png';
import founderImg from './img/founder/img.png';
import theketomatrixgreenlogo from './img/theketomatrixgreenlogo.png';
import theketomatrixblacklogo from './img/theketomatrixblacklogo.png';
import blogimg1 from './img/blog/img1.jpeg';
import blogimg2 from './img/blog/img2.jpeg';
import blogimg3 from './img/blog/img3.jpeg';
import blogimg4 from './img/blog/img4.jpeg';

import companyownerImg1 from './img/author1.png';
import companyownerImg2 from './img/author2.png';



import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";

function App() {
  return (

    <>
      <Router>

          <Routes>
              <Route path="/" element={<Homepage WeblogoImg={WeblogoImg} />} />
          </Routes>
      </Router>
    </>
  );
}

export default App;
